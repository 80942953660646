// ** Logo
import logo from '@src/assets/images/logo/logo.svg'

const SpinnerComponent = () => {
    return (
        <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%,-50%)'}}>
            <img src={logo} alt='logo' width={150} height={150}/>
        </div>
    )
}

export default SpinnerComponent
