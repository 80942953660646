// **  Initial State
const initialState = {
    admin: null,
    permissions: []
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {...state, admin: action.data}
        case 'PERMISSIONS':
            return {...state, permissions: action.data}
        case 'LOGOUT':
            return {...state, admin: null}
        default:
            return state
    }
}

export default authReducer
